<template>
  <v-card>
    <v-toolbar>
      <v-toolbar-title> Enrollment Reporting ({{ total }}) </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-form @submit.stop.prevent="onSubmit">
        <v-select
          class="mr-2"
          v-model="selectedParticipantReportFields"
          :items="participantReportFields"
          label="Select Columns"
          item-text="name"
          multiple
          chips
          flat
          deletable-chips
          solo-inverted
          hide-details
          return-object
          @change="updateHeaders"
        >
          <template v-slot:selection="{ item, index }">
            <span v-if="index === 1" class="grey--text caption">
              ({{ selectedParticipantReportFields.length }} columns selected)
            </span>
          </template>

          <template v-slot:prepend-item>
            <v-list-item ripple @click="toggleReportFields">
              <v-list-item-action>
                <v-icon :color="selectedParticipantReportFields.length > 0 ? 'indigo darken-4' : ''">
                  {{ iconReportFields }}
                </v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  Select All
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider class="mt-2"></v-divider> </template
        ></v-select>
      </v-form>
      <v-form @submit.stop.prevent="getData">
        <v-text-field
          class="mr-2"
          v-model="search"
          :label="$i18n.translate('Filter') + ' by ' + $i18n.translate('Keyword')"
          flat
          solo-inverted
          hide-details
          clearable
          clear-icon="mdi-close-circle-outline"
          @click:clear="onClear"
        ></v-text-field>
      </v-form>
      <v-menu offset-y :close-on-content-click="false">
        <template v-slot:activator="{ on: onMenu }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on: onTooltip }">
              <v-btn class="mr-2" small fab v-on="{ ...onMenu, ...onTooltip }">
                <v-icon>mdi-magnify</v-icon>
              </v-btn>
            </template>
            <span>{{ $i18n.translate("Advanced Search") }}</span>
          </v-tooltip>
        </template>
        <v-list>
          <v-list-item-group color="primary">
            <v-subheader>{{ $i18n.translate("Advanced Search") }}</v-subheader>
            <v-list-item selectable>
              <v-list-item-content>
                <OrganizationsField
                  dense
                  v-model="selectedOrganizations"
                  :label="$i18n.translate('Organizations')"
                  @change="getData"
                  :showKey="true"
                  hide-details
                  clearable
                ></OrganizationsField>
              </v-list-item-content>
            </v-list-item>
            <v-list-item selectable>
              <v-list-item-content>
                <ParticipantTypesField
                  dense
                  v-model="selectedParticipantTypes"
                  :label="$i18n.translate('Participant Types')"
                  @change="getData"
                  hide-details
                  clearable
                ></ParticipantTypesField>
              </v-list-item-content>
            </v-list-item>
            <v-list-item selectable>
              <v-list-item-content>
                <ParticipantStatusesField
                  dense
                  v-model="selectedParticipantStatuses"
                  :label="$i18n.translate('Participant Statuses')"
                  @change="getData"
                  hide-details
                  clearable
                ></ParticipantStatusesField>
              </v-list-item-content>
            </v-list-item>
            <v-list-item selectable>
              <v-list-item-content>
                <DateTimePickerField
                  dense
                  hide-details
                  @input="getData"
                  label="Enrollment Begin Date"
                  v-model="selectedEnrolledAfter"
                  defaultTime="00:00"
                ></DateTimePickerField>
              </v-list-item-content>
            </v-list-item>
            <v-list-item selectable>
              <v-list-item-content>
                <DateTimePickerField
                  dense
                  hide-details
                  @input="getData"
                  label="Enrollment End Date"
                  v-model="selectedEnrolledBefore"
                  defaultTime="23:59"
                ></DateTimePickerField>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-menu>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="mr-2"
            small
            fab
            v-bind="attrs"
            v-on="on"
            @click="onSubmit()"
            :disabled="items.length == 0 || isGeneratingReport"
          >
            <v-icon v-if="!isGeneratingReport">mdi-exit-to-app</v-icon>
            <v-progress-circular indeterminate color="primary" v-if="isGeneratingReport"></v-progress-circular>
          </v-btn>
        </template>
        <span>Export Report ({{ total }})</span>
      </v-tooltip>
    </v-toolbar>
    <v-card-text>
      <span v-if="hasFilters">Filters:</span>
      <span v-if="search && search.length > 0">
        <v-chip
          class="mr-2 mb-2"
          close
          @click:close="
            search = '';
            getData();
          "
          >Keyword: {{ search }}</v-chip
        >
      </span>
      <span v-if="selectedOrganizations && selectedOrganizations.length > 0">
        <v-chip
          class="mr-2 mb-2"
          v-for="(organization, i) in selectedOrganizations"
          :key="i"
          close
          @click:close="
            selectedOrganizations.splice(i, 1);
            getData();
          "
          >Company: {{ organization.name }} - {{ organization.organizationKey }}</v-chip
        >
      </span>
      <span v-if="selectedParticipantTypes && selectedParticipantTypes.length > 0">
        <v-chip
          class="mr-2 mb-2"
          v-for="(participantType, i) in selectedParticipantTypes"
          :key="i"
          close
          @click:close="
            selectedParticipantTypes.splice(i, 1);
            getData();
          "
          >{{ $i18n.translate("Participant Type") }}: {{ participantType.name }}</v-chip
        >
      </span>
      <span v-if="selectedParticipantStatuses && selectedParticipantStatuses.length > 0">
        <v-chip
          class="mr-2 mb-2"
          v-for="(participantStatus, i) in selectedParticipantStatuses"
          :key="i"
          close
          @click:close="
            selectedParticipantStatuses.splice(i, 1);
            getData();
          "
          >{{ $i18n.translate("Participant Status") }}: {{ participantStatus.name }} -
          {{ participantStatus.description }}</v-chip
        >
      </span>
      <span v-if="selectedEnrolledAfter">
        <v-chip
          class="mr-2 mb-2"
          close
          @click:close="
            selectedEnrolledAfter = undefined;
            getData();
          "
          >Enrollment Begin Date: {{ selectedEnrolledAfter | formatDateClient("MM/DD/YYYY", selectedClient) }}</v-chip
        >
      </span>
      <span v-if="selectedEnrolledBefore">
        <v-chip
          class="mr-2 mb-2"
          close
          @click:close="
            selectedEnrolledBefore = undefined;
            getData();
          "
          >Enrollment End Date: {{ selectedEnrolledBefore | formatDateClient("MM/DD/YYYY", selectedClient) }}</v-chip
        >
      </span>
      <DraggableHeaderTable
        dense
        fixed-header
        height="50vh"
        :headers="headers"
        :items="items"
        :loading="loading"
        :options.sync="options"
        :server-items-length="total"
        :footer-props="{ 'items-per-page-options': [10, 25, 50] }"
      >
        <template v-for="(header, i) in headers" v-slot:[`item.${header.value}`]="{ item }">
          <span :key="i">
            <span v-if="header.date === true"
              >{{ resolve(header.value, item) | formatDateClient("MM/DD/YYYY", selectedClient) }}
            </span>
            <span v-else>{{ resolve(header.value, item) }}</span>
          </span>
        </template>
      </DraggableHeaderTable>
    </v-card-text>
  </v-card>
</template>

<script>
import DraggableHeaderTable from "../../gapp-components/components/tables/DraggableHeaderTable.vue";
import DateTimePickerField from "../../gapp-components/components/fields/DateTimePickerField.vue";
import ParticipantStatusesField from "../../gapp-components/components/fields/ParticipantStatusesField.vue";
import ParticipantTypesField from "../../gapp-components/components/fields/ParticipantTypesField.vue";
import OrganizationsField from "../../gapp-components/components/fields/OrganizationsField.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    OrganizationsField,
    ParticipantTypesField,
    ParticipantStatusesField,
    DateTimePickerField,
    DraggableHeaderTable
  },
  name: "EnrollmentReporting",
  metaInfo: {
    title: "Enrollment Reporting"
  },
  data() {
    return {
      valid: false,
      loading: false,

      search: "",
      selectedParticipantReportFields: [],
      selectedParticipantStatuses: [],
      selectedParticipantTypes: [],
      selectedOrganizations: [],
      selectedEnrolledAfter: undefined,
      selectedEnrolledBefore: undefined,

      filters: {},
      isGeneratingReport: false,
      fieldsSelected: [],
      participantReportFields: [],
      headers: [],
      items: [],
      total: 0,
      options: {
        itemsPerPage: 10,
        page: 1
      }
    };
  },
  methods: {
    resolve(path, obj) {
      return path.split(".").reduce(function(prev, curr) {
        return prev ? prev[curr] : null;
      }, obj || self);
    },
    onClear() {
      this.search = "";
      this.selectedParticipantReportFields = [];
      this.selectedParticipantStatuses = [];
      this.selectedParticipantTypes = [];
      this.selectedOrganizations = [];
      this.getData();
    },
    onSubmit() {
      let count = 0;
      let reportHeaders = [];
      this.isGeneratingReport = true;
      this.headers.forEach(header => {
        header.name = reportHeaders.push({
          name: this.translateDescription(header.name),
          enumValue: header.enumValue,
          typeValue: header.typeValue,
          typeId: header.typeId,
          rank: count++
        });
      });

      this.filters = this.getFilters();

      let postForm = {
        name: "Enrollment Report",
        type: { name: "ENROLLMENT" },
        generationType: { name: "XLSX" },
        filters: this.filters,
        reportHeaders: reportHeaders
      };
      this.$api
        .post("/api/reports/generate", postForm)
        .then(() => {
          this.isGeneratingReport = false;
          this.$router.push({ name: "reportDownloads" });
        })
        .catch(error => {
          this.isGeneratingReport = false;
          console.log(error);
        });
    },
    onChangeHeaders(allHeaders) {
      this.headers = allHeaders;
      this.selectedParticipantReportFields = [];
      allHeaders.forEach(h => {
        this.selectedParticipantReportFields.push(h);
      });
    },
    updateHeaders() {
      this.headers = this.selectedParticipantReportFields;
      this.getData();
    },
    getFilters() {
      let filters = {};

      if (this.selectedParticipant.participantType.participantTypeKey == "300") {
        filters.organizationRelatedParticipantReportToParticipant = { id: this.selectedParticipant.id };
      }

      if (this.selectedParticipant.participantType.participantTypeKey == "400") {
        filters.organizationRelatedParticipant = { id: this.selectedParticipant.id };
      }

      if (this.search && this.search.length > 0) {
        filters.keyword = this.search;
      }

      if (this.selectedOrganizations && this.selectedOrganizations.length > 0) {
        filters.organizations = this.selectedOrganizations.map(organization => {
          return { id: organization.id };
        });
      }

      if (this.selectedParticipants && this.selectedParticipants.length > 0) {
        filters.participants = this.selectedParticipants.map(participant => {
          return { id: participant.id };
        });
      }

      if (this.selectedParticipantTypes && this.selectedParticipantTypes.length > 0) {
        filters.participantTypes = this.selectedParticipantTypes.map(participantType => {
          return { id: participantType.id };
        });
      }

      if (this.selectedParticipantStatuses && this.selectedParticipantStatuses.length > 0) {
        filters.statuses = this.selectedParticipantStatuses.map(status => {
          return { name: status.name };
        });
      }

      if (this.selectedEnrolledAfter) {
        filters.enrolledAfter = this.selectedEnrolledAfter;
      }

      if (this.selectedEnrolledBefore) {
        filters.enrolledBefore = this.selectedEnrolledBefore;
      }

      return filters;
    },
    translateDescription(v) {
      v = v.replaceAll("Participant", this.$i18n.translate("Participant"));
      v = v.replaceAll("Organization", this.$i18n.translate("Organization"));
      return v;
    },
    getData() {
      this.loading = true;
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;

      this.filters = this.getFilters();

      this.$api
        .post(
          "/api/participants/search?size=" +
            itemsPerPage +
            "&page=" +
            (page - 1) +
            (sortBy && sortBy.length > 0
              ? "&sort=" + sortBy[0] + "," + ((sortDesc && sortDesc.length > 0) & sortDesc[0] ? "DESC" : "ASC")
              : ""),
          this.filters
        )
        .then(({ data }) => {
          this.loading = false;
          this.items = data.content;
          this.total = data.totalElements;
          for (let item of this.items) {
            item.optIn = item.optIn ? "Yes" : "No";
          }

          this.items
            .reduce((p, item) => {
              return p.then(() => this.getCpgs(item.organization));
            }, Promise.resolve())
            .then(() => {
              this.loading = false;
              this.$forceUpdate();
            });
        })
        .catch(() => {
          this.loading = false;
        });
    },
    getCpgs(organization) {
      return this.$api.get("/api/organizations/" + organization.id + "/organizationGroups").then(({ data }) => {
        organization.cpgs = data._embedded.organizationGroups
          .filter(item => item.category == "CPG")
          .map(item => item.name)
          .join(", ");
        return organization;
      });
    },
    fetchParticipantReportFields() {
      this.participantReportFields = [];
      return this.$api.get("/api/types/report/participantReportFields").then(({ data }) => {
        this.participantReportFields = [];
        data.forEach(field => {
          field.description = this.translateDescription(field.description);
          this.participantReportFields.push({
            name: field.description,
            enumValue: field.name,
            typeValue: "ParticipantReportFieldType",
            value: field.vueValue,
            align: field.align ? field.align.toLowerCase() : "left",
            sortable: false,
            text: field.description,
            numeric: field.numeric,
            date: field.date
          });
        });

        let namesToRemove = ["PARTICIPANT_EMAIL2", "PARTICIPANT_PHONE2", "ORGANIZATION_PHONE2"];
        this.participantReportFields = this.participantReportFields.filter(
          v => namesToRemove.indexOf(v.enumValue) == -1
        );

        this.selectedParticipantReportFields = this.participantReportFields;
        this.updateHeaders();
      });
    },
    toggleReportFields() {
      this.$nextTick(() => {
        if (this.allReportFields) {
          this.selectedParticipantReportFields = [];
        } else {
          this.selectedParticipantReportFields = this.participantReportFields.slice();
        }
        this.updateHeaders();
      });
    }
  },
  watch: {
    options: {
      handler() {
        this.getData();
      },
      deep: true
    }
  },
  computed: {
    ...mapGetters(["selectedParticipant", "selectedClient"]),
    hasFilters() {
      return Object.keys(this.filters).length > 0;
    },
    allReportFields() {
      return this.selectedParticipantReportFields.length === this.participantReportFields.length;
    },
    someReportFields() {
      return this.selectedParticipantReportFields.length > 0 && !this.allReportFields;
    },
    iconReportFields() {
      if (this.allReportFields) return "mdi-close-box";
      if (this.someReportFields) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    }
  },
  mounted() {
    this.$store.dispatch("setBreadcrumb", [
      {
        text: this.$i18n.translate("Dashboard"),
        to: { name: "dashboard" },
        exact: true
      },
      {
        text: "Reports"
      },
      { text: "Enrollment Reporting" }
    ]);

    this.fetchParticipantReportFields();
  }
};
</script>
